import {
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Host,
  HostListener,
  Input,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { GraphService } from "src/app/kernel/graphql/services/graph.service";
import { BaseDropdown } from "src/app/kernel/form/classes/base-dropdown";
import { TranslationService } from "../../../../../../translations/translation.service";
import { ScrollerOptions } from "primeng/scroller";
import { compareDeep } from "src/app/kernel/shared/helpers/object.helper";
import { SxwFormComponent } from "src/app/kernel/form/components/sxw-form/sxw-form.component";
import { MultiSelect } from "primeng/multiselect";
import { Dropdown } from "primeng/dropdown";

@Component({
  selector: "sxw-dropdown-input",
  templateUrl: "./dropdown-input.component.html",
  styleUrls: ["./dropdown-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownInputComponent),
      multi: true,
    }
  ],
})
export class DropdownInputComponent extends BaseDropdown implements OnChanges {
  protected isPanelOpened = false;
  protected delayedIsPanelOpened = false;

  @Input() useValue: any;
  @Input() placeholder = "SelectItem";
  @Input() optionIcon?:string;
  @Input() emptyMessage = "";
  @Input() autoDisplayFirst = false;
  @Input() multiple = false;
  @Input() isFilter = true;
  @Input() label!: string;
  @Input() appendTo: any = "body";
  @Input() multiSelectLabelDisplay = "chip";
  @Input() resetOnFiltersChange: ((filters: any, value: any) => boolean) | boolean = true;
  @Input() theme = "sxw-theme";
  @Input() styleClass = "full-width-input";
  @Input() required = false;
  @Input() dropdownIcon = "pi pi-chevron-down";
  @Input() optionDisabled: string = "disabled"; // Name of the disabled field of an option.
  @Input() filterMatchMode: string = "contains";
  @Input() virtualScrollOptions: ScrollerOptions = {
    onScroll: this.onOverlayScroll.bind(this),
    lazy: true,
    delay: 10,
  };

  @ContentChild(TemplateRef) content?: TemplateRef<any>;
  @ContentChild("selectedContent") selectedContent?: TemplateRef<any>;
  @ViewChild("pMultiSelect") pMultiSelect?: MultiSelect;
  @ViewChild(Dropdown) pDropdown?: Dropdown;

  @Output() onOverlayClosedEvent:EventEmitter<void>= new EventEmitter();

  constructor(
    graph: GraphService,
    translationService: TranslationService,
    @Optional() @Host() public sxwForm?: SxwFormComponent
  ) {
    super(graph, translationService);
  }

  // =================== Angular Lifecycle hooks { =================== //
  /**
   * Angular lifecycle hook
   * deals with data source changes, options changes and value changes.
   * @param changes
   */
  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes["query"] || changes["getDataMethod"]) {
      this.handleDataSourceChange();
    }
    if (changes["options"]) {
      this.handleOptionsChange();
    }
    if (changes["value"]) {
      this.setValue(changes["value"].currentValue);
    }
    if (changes["useValue"]) {
      this.handleUseValueChange(changes["useValue"].currentValue);
    }
  }

  // =================== } Angular Lifecycle hooks =================== //

  // =================== Change Handlers { =================== //
  /**
   * handles query/getDataMethod changes
   * @protected
   */
  protected handleDataSourceChange() {
    if (!this._locked && this.shouldResetOnFilterChange()) {
      this.setValue(null);
      this.onChange(null);
    }
    this.loadData(); // perform the load data method that fits.
  }

  /**
   * Indicates if the dropdown will be reseted or not.
   */
  shouldResetOnFilterChange() {
    if (typeof this.resetOnFiltersChange === 'function') {
      return this.resetOnFiltersChange(this.query?.params, this.value)
    }
    return this.resetOnFiltersChange
  }

  /**
   * Sets the value when the useValue is changes.
   * @param changes
   */
  handleUseValueChange(value: any) {
    if (!value || compareDeep(this.prepareOutputValue(this.value), this.prepareOutputValue(value))) {
      return ;
    }
    this.setValue(value);
    this.onChange(this.prepareOutputValue(value));
  }

  /**
   * Handles the change of dropdown options input.
   */
  handleOptionsChange() {
    this.setOptions(this.options);
    if(this.control?.value) this.setValue(this.control.value);
    if (!this.nullable && !this.value && this.options) {
      this.setValue(this.options[0]);
    }
  }
  
  protected changePanelState(opened: boolean){
    this.isPanelOpened = opened;
    setTimeout((()=> this.delayedIsPanelOpened = opened))
  }

  @HostListener("window:keydown.enter")
  protected handlePressEnter() {
    if (this.delayedIsPanelOpened && this.options.length) {
      this.onChangeHandler({ value: this.pDropdown?.findPrevEnabledOption(1) });
      this.pDropdown?.hide();
    }
  }
  // =================== } Change Handlers =================== //

  // =================== Load Data Methods { =================== //
  /**
   * Handle infinite scroll pagination.
   * @param originalEvent
   * @protected
   */
  protected onOverlayScroll({ originalEvent }: any) {
    const { clientHeight, scrollTop, scrollHeight } = originalEvent.target;
    if (
      scrollTop + clientHeight >= scrollHeight * 0.75 &&
      !this._allPagesLoaded &&
      (!this._loadDataSubscription || this._loadDataSubscription.closed)
    ) {
      this._page += 1;
      this.loadData(false);
    }
  }
  // =================== } Load Data Methods =================== //

  protected removeChip(chip: any, event: MouseEvent) {
    if(!Array.isArray(this.value) || !this.pMultiSelect) return;
    return this.pMultiSelect.removeChip(chip, event)
  }

  // =================== handle shape of data summary =================== //

  override get summaryValue():any {
    return this.value?.icon || (Array.isArray(this.value)&& this.value[0]?.icon !== null && this.value[0]?.icon !== undefined) ? (this.value?.icon ?
      `<div class="flex-row-end gap-5">
        <span><i class="${ this.value?.icon }"></i></span>
        <span>${this.value?.value ?? ''}</span>
      </div>`
      : `<div class="multiple-value gap-5">${this.value.map((ele: any) => {
          return `<div class="value-label multi-items" ><i class="${ele?.icon} icon-padding"></i>
            ${ele?.value ?? ''}</div>`
        }).join('')}</div>`)
      :  super.summaryValue;
  }

  /**
   * important note: we should define filterBy property if need to filter on static options.
   * @param event 
   */
  protected filterStaticOptions(event: HTMLInputElement) {
    if(this.multiple) {
      this.pMultiSelect?.filterOptions.filter?.(event);
    }
  }


  protected override updateFilter(event: any): void {
    if(!this.query) {
      this.filterStaticOptions(event as HTMLInputElement);
    } else if(this.multiple && this.query) {
      super.updateFilter({filter: event?.target.value});
    } else {
      super.updateFilter(event);
    }
  }
}
